import React from 'react'

import TermsOfService from '../components/modal/terms-of-service'

const TermsOfServicePage = () => (
  <div className="container">
    <div className="mv4">
      <TermsOfService />
    </div>
  </div>
)

export default TermsOfServicePage
